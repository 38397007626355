import request from "@/utils/request";

export async function queryAccounts() {
  const requestConfig = {
    url: "/accounts",
    method: "get"
  };
  const response = await request(requestConfig);
  console.log("queryAccounts response:", response);
  if (response.code === 0) {
    return response.data;
  } else {
    return [];
  }
}

export async function deleteAccount(accountId) {
  console.log("onAccountDelete:", accountId);
  const requestConfig = {
    url: `/accounts/${accountId}`,
    method: "delete"
  };
  const response = await request(requestConfig);
  console.log("updateAccount response:", response);
  return (response.code === 0);
}

export async function createAccount(form) {
  const requestConfig = {
    url: "/accounts",
    method: "post",
    data: form
  };
  const response = await request(requestConfig);
  console.log("createAccount response:", response);
  if (response.code === 0) {
    return response.data;
  } else {
    return null;
  }
}

export async function updateAccount(form) {
  const requestConfig = {
    url: `/accounts/${form.accountId}`,
    method: "put",
    data: form
  };
  const response = await request(requestConfig);
  console.log("updateAccount response:", response);
  if (response.code === 0) {
    return response.data;
  } else {
    return null;
  }
}
